<template>
  <div>
    <Toolbar :handle-delete="deleteItem" :handle-edit="() => editHandler(item)">
      <template slot="left">
        <v-toolbar-title v-if="item">{{ `${$options.servicePrefix} ${item['@id']}` }}</v-toolbar-title>
      </template>
    </Toolbar>
    <br/>
    <div v-if="item">
      <v-card max-width="860px" class="mt-4 mx-auto">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>{{ $t('Address') }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-center">
          <h1 class="font-weight-light mb-3 black--text">
            {{ item.type }} {{ item.streetNr }} {{ item.additional }}
            {{ item.zipCode }}
            {{ item.city }}
            {{ item.country }}
          </h1>
        </v-card-text>
      </v-card>
    </div>
    <Loading :visible="isLoading"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import Loading from '../../components/Loading';
import ShowMixin from '../../mixins/ShowMixin';
import Toolbar from '../../components/Toolbar';

const servicePrefix = 'Address';

export default {
  name: 'AddressShow',
  servicePrefix,
  components: {
    Loading,
    Toolbar
  },
  mixins: [ShowMixin],
  computed: {
    ...mapFields('address', {
      isLoading: 'isLoading'
    }),
    ...mapGetters('address', ['find'])
  },
  methods: {
    ...mapActions('address', {
      deleteItem: 'del',
      editItem: 'edit',
      reset: 'resetData',
      retrieve: 'load'
    }),
  },
};
</script>
